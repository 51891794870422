/* You can add global styles to this file, and also import other style files */
.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}

/* Sweet alert */

.swal2-title {
    display: inline-block !important;
}

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
    content: "";
}

@keyframes glowing {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

.glowing {
    animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 99999 !important;
    width: 100%;
    height: 100%;
}

.root-initial-loader i {
    position: absolute;
    color: rgb(91, 167, 234);
    z-index: 100000 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}

ngx-spinner .loading-text {
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}

.container-nopad .ui-panel-content.ui-widget-content {
    padding: 0 !important;
    font-family: Poppins;
}

.custom-p-panel .ui-panel {
    font-family: Poppins, Helvetica, "sans-serif";
}

.custom-p-panel .ui-panel .ui-panel-titlebar .ui-panel-title {
    font-weight: 600;
}

.custom-p-panel .ui-panel .ui-panel-titlebar.ui-widget-header {
    border: 1px solid #E5EAEE !important;
    background-color:rgba(0, 0, 0, 0.03);
}

.task-group-table.table th,
.task-group-table.table td {
    padding-left: .1rem;
    padding-right: .1rem;
}

.task-group-table.table td.tg-drag-icon {
    /* padding-right: 0.5rem; */
}

.p-dropdown-listing-filter > .form-control.ui-dropdown,
.p-multiselect-listing-filter > .form-control.ui-multiselect {
    display: flex;
    align-items: center;
    padding: 0 0.6rem 0 0.6rem;
    font-family: Poppins;
    border-color: #E5EAEE;
    border-radius: 0.42rem;
    min-width: unset;
}

.p-dropdown-listing-filter > .form-control.ui-dropdown .ui-dropdown-label,
.p-multiselect-listing-filter > .form-control.ui-multiselect .ui-multiselect-label {
    padding-right: 3.5em;
}

.p-dropdown-listing-filter .ui-dropdown > .ui-dropdown-label-container > .ui-dropdown-clear-icon,
.ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close {
    font-size: .73rem;
}

.ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.p-dropdown-panel-high-z {
    z-index: 1000;
}

.form-control .ui-inputtext,
.form-control .ui-multiselect-label {
    font-size: 1rem !important;
}

.p-dropdown-listing-filter > .form-control.ui-dropdown:hover,
.p-multiselect-listing-filter > .form-control.ui-multiselect:hover{
    border-color: #E5EAEE;
}

.ui-chips > ul.ui-inputtext {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #464E5F;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #E5EAEE;
    border-radius: 0.42rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.ui-chips > ul.ui-inputtext:focus {
    color: #464E5F;
    background-color: #ffffff;
    border-color: #69b3ff !important;
    outline: 0; }
.ui-chips > ul.ui-inputtext:focus:active,
.ui-chips > ul.ui-inputtext:focus.active,
.ui-chips > ul.ui-inputtext:focus:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
.ui-chips > ul.ui-inputtext:not(.ui-state-disabled).ui-state-focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ui-chips > ul.ui-inputtext:not(.ui-state-disabled):hover {
    border-color: #69b3ff !important ;
}

.form-control-sm .ui-inputtext {
    font-size: 0.925rem !important;
}

.p-dropdown-listing-filter > .form-control-sm.ui-dropdown
.p-multiselect-listing-filter > .form-control-sm.ui-multiselect {
    border-color: #E5EAEE;
    border-radius: 0.28rem;
    height: calc(1.35em + 1.1rem);
}

.form-control.ui-dropdown .ui-dropdown-label.ui-placeholder,
.form-control.ui-multiselect .ui-multiselect-label.ui-placeholder {
    color: #B5B5C3;
    opacity: 1;
}

.form-control-sp.ui-dropdown .ui-dropdown-label.ui-placeholder,
.form-control-sp.ui-multiselect .ui-multiselect-label.ui-placeholder {
    color: inherit;
    opacity: 1;
}

.ui-dropdown-trigger > .ui-dropdown-trigger-icon,
.ui-dropdown > .ui-dropdown-label-container > .ui-dropdown-clear-icon,
.ui-multiselect-trigger > .ui-multiselect-trigger-icon,
.ui-multiselect > .ui-multiselect-label-container > .ui-multiselect-clear-icon {
    font-size: 0.72rem;
    font-weight: bold;
    color: rgb(71, 71, 71);
}

.ui-dropdown > .ui-dropdown-label-container > .ui-dropdown-clear-icon,
.ui-multiselect > .ui-multiselect-label-container > .ui-multiselect-clear-icon {
    right: 3em;
}

.ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter,
.ui-dropdown-panel .ui-dropdown-items-wrapper,
.ui-dropdown-items-wrapper .ui-dropdown-items,
.ui-multiselect-panel .ui-multiselect-filter-container .ui-multiselect-filter,
.ui-multiselect-panel .ui-multiselect-items-wrapper,
.ui-multiselect-items-wrapper .ui-multiselect-items {
    font-family: Poppins;
    font-size: 0.925rem;
}

.ui-dropdown-panel .ui-dropdown-items-wrapper .ui-dropdown-items .ui-dropdown-item,
.ui-multiselect-panel .ui-multiselect-items-wrapper .ui-multiselect-items .ui-multiselect-item {
    padding: 0.1rem 0.6rem 0.1rem 0.6rem;
}

.cursor-pointer {
    cursor: pointer;
}

.job-group-popover-container {
    min-width: 220px;
}

.ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
    font-size: 13px !important;
    line-height: unset;
}

/* Chrome, Safari, Edge, Opera */
input.hide-arrows-input-number::-webkit-outer-spin-button,
input.hide-arrows-input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.hide-arrows-input-number[type=number] {
  -moz-appearance: textfield;
}

.costera-offcanvas {
    /* margin-top: 118px; */
}

.costera-offcanvas.offcanvas.offcanvas-right {
    right: -455px;
}

.offcanvas.costera-offcanvas {
    width: 450px;
}

.next-to-costera-aside {
    -webkit-transition: margin-right 0.3s ease;
    transition: margin-right 0.3s ease;
}

.header-next-to-costera-aside {
    -webkit-transition: margin-right 0.3s ease;
    transition: right 0.3s ease !important;
}

.header-fixed[data-fixed-header-override="on"][data-fixed-header-override="custom"] .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 97;
    height: 145px;
    -webkit-animation: header-scroll-animation 0.5s ease 1;
    animation: header-scroll-animation 0.5s ease 1;
  }

.header-fixed[data-header-scroll="on"][data-fixed-header-override="custom"] .header .header-bottom {
    display: flex;
}

div#kt_wrapper.header-fixed.next-to-costera-aside.wrapper .container {
    max-width: unset;
}

div#kt_wrapper.header-fixed.next-to-costera-aside.wrapper .header-top {
    background-color: #70b6fe;
}

@media (min-width: 1200px) {
    .costera-aside-is-open {
        margin-right: 450px;
    }

    .header-costera-aside-is-open {
        right: 450px !important;
    }
}

@media (min-width: 992px) {
    .header-menu .menu-nav > .menu-item > .menu-link {
        border-radius: 0.42rem;
        padding: 0.85rem 0.75rem !important;
    }
}

.btn i.fa-spin {
    padding-right: unset;
    margin-right: .35rem;
}

.ql-toolbar.ql-snow {
    background-color: #f4f4f4 !important;
    border: 1px solid #ccc !important;
}

.ql-container.ql-snow {
    border: 1px solid #ccc !important;
}

.click-pointer {
    cursor: pointer;
}

@media (min-width: 992px) {
    .aside-enabled.subheader-fixed .subheaders-container {
        left: 265px;
    }
}

@media (min-width: 992px) {
    .subheader-fixed.aside-minimize-hover .subheaders-container,
    .subheader-fixed.aside-minimize .subheaders-container {
        left: 70px;
    }
}
